import {useForm, usePage, router} from '@inertiajs/react'
import {Button, Card, Col, Container, Form, InputGroup, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {useLaravelReactI18n} from "laravel-react-i18n";
import InertiaTable from "laravel-inertia-react-table"
import React, {useRef, useState} from "react";
import Icon from '@mdi/react';
import {mdiContentSave, mdiGroup, mdiUngroup} from '@mdi/js';

export default function Edit() {
    const {t, getLocales} = useLaravelReactI18n();
    const {product, items, sortfield, initialSelectedItems, sortorderSplitmode} = usePage().props;
    const [selectedItems, _setSelectedItems] = useState(initialSelectedItems);
    const [imagePreview, setImagePreview] = useState(null);
    const [colorImagePreview, setColorImagePreview] = useState(null);
    const selectedItemsRef = useRef(selectedItems); // Workarround für bug in Inertia
    // Aktualisieren Sie die ref jedes Mal, wenn setSelectedItems aufgerufen wird
    const setSelectedItems = (data) => {
        if (typeof data === 'function') {
            _setSelectedItems(prevState => {
                const updatedState = data(prevState);
                selectedItemsRef.current = updatedState;
                return updatedState;
            });
        } else {
            selectedItemsRef.current = data;
            _setSelectedItems(data);
        }
    };
    const [viewMode, setViewMode] = useState(false);
    const handleViewButtonClick = () => {
        setViewMode(!viewMode);
        router.reload({data: {'viewmode': viewMode}});

    }

    const [sortMode, setSortMode] = useState(false);
    const handleSortButtonClick = () => {
        setSortMode(!sortMode);
    }


    const {data, setData, post, processing, progress, transform, errors} = useForm({
        title: product.title,
        sku: product.sku,
        sortorder: product.sortorder,
        sortorder2: product.sortorder2,
        image: product.preview_image,
        color_code: product.color_code,
        color_image: product.color_image
    });

    const tableOptions = {selectable: !sortMode, showCheckbox: !sortMode, updateOnly: ['items']};


    transform((data) => {
        console.log("SelectedItems in transform");
        console.log(selectedItemsRef.current); // hier ist das aktuellste selectedItems
        return {
            ...data,
            selectedItems: selectedItemsRef.current,
        };
    });

    function handleChange(e) {
        const key = e.target.id;
        const value = e.target.value;
        setData({
            ...data,
            [key]: value,
        });
    }

    const handleColorPickerChange = (e) => {
        const value = e.target.value.replace(/^#/, '');
        setData(prevData => ({
            ...prevData,
            color_code: value,
        }));
    }

    const handleFileChange = (e) => {

        const file = e.target.files[0];
        const sourceId = e.target.id;
        const reader = new FileReader();
        reader.onload = () => {
            if (sourceId === 'color_image') {
                setColorImagePreview(reader.result);
            } else {
                setImagePreview(reader.result);
            }
        };
        reader.readAsDataURL(file);

        setData({...data, [sourceId]: e.target.files[0]});
    }

    const handleConvertToSimpleClick = () => {
        router.get(`/product/${product.id}/convert/simple`);
    }

    const handleConvertToParentClick = () => {
        router.get(`/product/${product.id}/convert/parent`);
    }

    const handleSortorderSwitchChange = (value) =>{
        console.log(value);
        router.reload({data:{sortfield:value}});
    }

    function handleSubmit(e) {
        e.preventDefault();


        post(`/product/${product.id}`, {forceFormData: true});
    }

    const customToolbarButtons = (<>
        <ToggleButton type="checkbox"
                      variant="outline-secondary"
                      checked={viewMode}
                      value="1"
                      onClick={handleViewButtonClick}>{t('show all')}</ToggleButton>
        <ToggleButton type="checkbox" className={"ms-2"}
                      variant="outline-secondary"
                      checked={sortMode}
                      value="1" onClick={handleSortButtonClick}>Sortieren</ToggleButton>
        <ToggleButtonGroup className={'ms-2'} name="sortorder_switch" value={sortfield} type="radio" onChange={handleSortorderSwitchChange}>
            <ToggleButton disabled={!sortorderSplitmode} id={"switch-sortorder1"} variant="secondary" value={"sortorder"}>{t('Sort B2C')}</ToggleButton>
            <ToggleButton disabled={!sortorderSplitmode} id={"switch-sortorder2"} variant="secondary" value={"sortorder2"}>{t('Sort B2B')}</ToggleButton>
        </ToggleButtonGroup>
    </>);


    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Container>
                <Card>
                    <Card.Header>
                        <div className="d-flex justify-content-between">
                            <h5>{`${t('edit product')} - ${data.sku}`}</h5>
                            <div className={'button-group'}>
                                {product.product_type === 'parent' &&
                                    <Button onClick={handleConvertToSimpleClick} className={'me-2'}>
                                        <Icon path={mdiUngroup} size={1}/> {t('convert to simple product')}
                                    </Button>}
                                {product.product_type === 'simple' &&
                                    <Button onClick={handleConvertToParentClick} className={'me-2'}>
                                        <Icon path={mdiGroup} size={1}/> {t('convert to grouped product')}
                                    </Button>}
                                <Button type="submit">
                                    <Icon path={mdiContentSave} size={1}/> {t('save')}
                                </Button>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                {t('title')}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control className={errors.title ? 'is-invalid' : ''} placeholder="title"
                                              id="title" value={data.title} onChange={handleChange}/>
                                {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                {t('sku')}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control className={errors.sku ? 'is-invalid' : ''} placeholder={t('sku')} id="sku"
                                              value={data.sku} onChange={handleChange}/>
                                {errors.sku && <div className="invalid-feedback">{errors.sku}</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                {t('sort key 1')}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control className={errors.sortorder ? 'is-invalid' : ''} placeholder="sortorder"
                                              id="sortorder" value={data.sortorder} onChange={handleChange}/>
                                {errors.sortorder && <div className="invalid-feedback">{errors.sortorder}</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                {t('sort key 2')}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control disabled={!sortorderSplitmode} className={errors.sortorder ? 'is-invalid' : ''} placeholder="sortorder2"
                                              id="sortorder2" value={data.sortorder2} onChange={handleChange}/>
                                {errors.sortorder2 && <div className="invalid-feedback">{errors.sortorder2}</div>}
                            </Col>
                        </Form.Group>

                        {/* Existing Image */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                {t('image')}
                            </Form.Label>
                            <Col sm={10}>
                                {data.image && !(data.image instanceof File) ? (
                                        <img src={data.image} alt="" width="100"/>) :
                                    <img src={imagePreview} alt="" width="100"/>}
                            </Col>
                        </Form.Group>

                        {/* File Input */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                {t('new image')}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control type={"file"} id="image" onChange={handleFileChange}/>
                            </Col>
                        </Form.Group>

                        {product.product_type === 'simple' && <>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    {t('color code')}
                                </Form.Label>
                                <Col sm={10}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">#</InputGroup.Text>
                                        <Form.Control className={errors.color_code ? 'is-invalid' : ''}
                                                      placeholder="000000"
                                                      id="color_code" value={data.color_code} onChange={handleChange}/>
                                        <Form.Control
                                            type="color"
                                            id="exampleColorInput"
                                            value={'#' + data.color_code}
                                            title="Choose your color"
                                            onChange={handleColorPickerChange}
                                        />

                                    </InputGroup>
                                    {errors.color_code && <div className="invalid-feedback">{errors.color_code}</div>}
                                </Col>
                            </Form.Group>

                            {/* Existing Color Image */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    {t('color image')}
                                </Form.Label>
                                <Col sm={10}>
                                    {data.color_image && !(data.color_image instanceof File) ? (
                                            <img src={data.color_image} alt="" width="100"/>) :
                                        <img src={colorImagePreview} alt="" width="100"/>}
                                </Col>
                            </Form.Group>

                            {/* File Input */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    {t('new image')}
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control type={"file"} id="color_image" onChange={handleFileChange}/>
                                </Col>
                            </Form.Group></>}

                    </Card.Body>
                </Card>
                {product.product_type === 'parent' && <Card className="mt-3">
                    <Card.Header>
                        <div className="d-flex justify-content-between">
                            <h5>Verknüpfte Artikel</h5>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <InertiaTable data={items} selectedIds={selectedItems} sortMode={sortMode}
                                      customToolbarButtons={customToolbarButtons} onSelectedIdsChange={setSelectedItems}
                                      options={tableOptions}/>
                    </Card.Body>
                </Card>}
            </Container>
        </Form>
    )
}
